import "./../../pages/services/services-page.css";

type ButtonViewCaseProps = {
    onClick?: () => void;
};

const ButtonViewCase: React.FC<ButtonViewCaseProps> = ({ onClick }) => {
    return (
        <div className='btn-view-case' onClick={onClick}>
            <button className='btn-text'>
                Просмотреть кейс
            </button>
            <button className='btn-arrow'>⭢</button>
        </div>
    );
};

export default ButtonViewCase;
