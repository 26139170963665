const Principles = () => {
    return(
        <div className='principles'>
            <h2 className='block-title'>ПРИНЦИПЫ РАБОТЫ</h2>
            <div className='principle'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img src='/Pictures/services/principle1.svg'/>
                </div>
                <div className='principle-about'>
                    <div className='principle-title'>Автоматизируем и оптимизируем бизнес-процессы</div>
                    <div className='principle-text'>Автоматизация помогает бизнесу кратно расти, а также собирать необходимые данные для прогнозирования развития бизнеса</div>
                </div>
            </div>
            <div className='principle'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img src='/Pictures/services/principle2.svg'/>
                </div>
                <div className='principle-about'>
                    <div className='principle-title'>Доверяем аналитике и пользовательскому опыту</div>
                    <div className='principle-text'>Анализируем пользовательский опыт людей, который помогает избежать множества вопросов и ошибок</div>
                </div>
            </div>
            <div className='principle'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img src='/Pictures/services/principle3.svg'/>
                </div>
                <div className='principle-about'>
                    <div className='principle-title'>Используем гибкую структуру разработки и собственную систему для ускорения разработки</div>
                    <div className='principle-text'>С помощью собственной системы «Atom» можем быстро собрать работающий МВП и выпустить на рынок</div>
                </div>
            </div>
        </div>
    )
}
export default Principles