import React, {Dispatch, SetStateAction, useState} from "react";
import InputMask from "react-input-mask";
import {useForm} from "react-hook-form";
import axios from "axios";
import popup from "./popup.module.css"

type FormData = {
    name: string;
    phone: string;
    email: string;
    subject: string;
    message: string;
    files: FileList;
};

const QuestionsPopup = (props: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>>}) => {
    const { open, setOpen} = props;
    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormData>();
    // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const token = process.env.REACT_APP_TELEGRAM_TOKEN;
    const onSubmit = async (data: FormData) => {
        const formData = new FormData();
        formData.append("Имя", data.name);
        formData.append("Телефон", data.phone);
        formData.append("e-mail", data.email);
        formData.append("Тема обращения", data.subject);
        formData.append("Текст сообщения", data.message);
        // for (let i = 0; i < data.files.length; i++) {
        //     formData.append("files", data.files[i]);
        // }
        try {
            await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
                chat_id: "-4227583515",
                text: `💻 Заявка на разработку:\nИмя: ${data.name}\nТелефон: ${data.phone}\ne-mail: ${data.email}\nТема обращения: ${data.subject}\nТекст сообщения: ${data.message}`
            });

            // for (let i = 0; i < selectedFiles.length; i++) {
            //     const fileData = new FormData();
            //     fileData.append("chat_id", "-4227583515");
            //     fileData.append("document", selectedFiles[i]);
            //     await axios.post(`https://api.telegram.org/bot${token}/sendDocument`, fileData);
            // }

            reset();
            // setSelectedFiles([]);
            alert("Заявка успешно отправлена!");
            setOpen(false);
        } catch (error) {
            console.error("Ошибка отправки:", error);
            alert("Ошибка отправки сообщения!");
        }
    };
    const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if ((event.target as HTMLDivElement).className === 'modal') {
            setOpen(false);
        }
    };
    return (
        <>
            {open &&
                (<div className='modal' onClick={handleModalClick}>
                    <div className='modal-content'>
                        <span className='close-button' onClick={() => setOpen(false)}>&times;</span>
                        <p className='vacancy-modal-title'>Оставьте заявку</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input className="vacancy-form-input"
                                   placeholder="Как к вам обращаться?"{...register("name", {required: true})} /> {errors.name &&
                            <span className="error-message">Укажите ваше имя</span>} <InputMask
                            className="vacancy-form-input"
                            mask="+7 (999) 999-99-99"
                            placeholder="+7 (000) 000-00-00"
                            id="phone-number"{...register(
                            "phone",
                            {required: true, pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/})} /> {errors.phone &&
                            <span className="error-message">Укажите корректный номер телефона</span>} <input
                            className="vacancy-form-input"
                            placeholder="Ваша почта"
                            type="email"{...register("email", {required: true})}/> {errors.email &&
                            <span className="error-message">Укажите адрес электронной почты</span>} <input
                            className="vacancy-form-input"
                            placeholder="Тема обращения"{...register("subject", {required: true})}/> {errors.subject &&
                            <span className="error-message">Укажите тему вашего обращения</span>} <textarea
                            className={`vacancy-form-input ${popup.message}`}
                            placeholder="Сообщение"
                            maxLength={500}{...register("message", {required: true})}></textarea> {errors.message &&
                            <span
                                className="error-message">Расскажите о вашей идее минимум в нескольких предложениях</span>}
                            <button type="submit" className="vacancy-submit-button">Отправить</button>
                        </form>
                    </div>
                </div>)
            }
        </>
    )
}

export default QuestionsPopup;