import React, {useContext} from "react";
import "./title.css"
import {QuestionsContext} from "../../App";
const TitleMain = () => {
    const setQuestionsModalVisible = useContext(QuestionsContext);
    const handleQuestionsClick = () => {
        if(!scrollTo('questions')) {
            setQuestionsModalVisible(true)
        }
    }
    const scrollTo = (elementId: string): boolean => {
        const block = document.getElementById(elementId);
        if (block) {
            const elementPosition = block.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 100;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
            return true
        }
        return false
    };
    return(
        <div className="title">
            <h1 className="corp-name">
                <b style={{ color: "#5CC3FD" }}>В</b><b>Р</b>
            </h1>
            <div className="title-first-text">
                <p>НОВЫЙ ВЗГЛЯД НА</p>
                <p>ЦИФРОВОЙ МИР</p>
            </div>
            <div className="title-second-text">
                <p>Разработка российского ПО и Веб-сервисов, не зависящих от иностранных решений</p>
            </div>
            <div className="title-order" onClick={handleQuestionsClick}>Оставить заявку</div>
        </div>
    )
}
export default TitleMain;