import styles from "./cases-page.module.css";
import ProbSol, {PSType} from "../../components/problem-solution/ProbSol";
import CaseComponent from "../../components/cases/Case";
import cases, {Case} from "../../mock/cases";
import Contacts from "../../components/contacts/Contacts";
import Footer from "../../components/footer/Footer";
import {useEffect} from "react";

const Skud = () => {
    useEffect(() => {
        window.scroll(0, 0);
    })
    return (
        <>
            <div className={styles.container}>
                <article>
                    <div className={styles.readingTime}>Время прочтения ~ 15 минут</div>
                    <h1>ПО ИНТЕГРАЦИИ СО СКУД</h1>
                    <h2>
                        ЗАКАЗЧИК: БАНКОВСКАЯ СФЕРА <br/>
                        <span className={styles.blue}>*ПОДПИСАН NDA</span>
                    </h2>
                    <ProbSol type={PSType.Problem}>
                        <p>Разработка корпоративной системы для приглашения гостей в офис.</p>
                    </ProbSol>
                    <ProbSol type={PSType.Solution}>
                        <p>
                            На момент начала разработки у заказчика уже присутствовала система СКУД, API управления
                            доступом на парковку, внутренняя система авторизации и система хранения учетных данных
                            сотрудников. Требовалось создать систему, которая сможет интегрироваться со всем перечисленными
                            сервисами.
                        </p>
                        <p>
                            Целью разрабатываемой системы является создание возможности сотруднику
                            из-под соответствующей страницы корпоративного портала создать заявку на приглашение гостя в
                            офис. После создание заявки предполагается верификация гостя в онлайн или очном формате, а
                            также выдача бумажного пропуска для прохода через СКУД в указанный день. Пропуск печатается на
                            терминале.
                        </p>
                        <p>
                            Таким образом требовалось создать сложное решение, состоящее из нескольких узлов, которые
                            связаны друг с другом и интегрируются с сервисами заказчика.
                        </p>
                    </ProbSol>
                    <img src="/Pictures/cases/skud/ui1.png" alt="" className={styles.img}/>
                    <h2>ОПИСАНИЕ ПРОБЛЕМЫ:</h2>
                    <section>
                        <p>
                            Основной проблемой заказчика, побудившей его к разработке, являлась потребность
                            автоматизировать процесс регулярного приглашений в офис гостей, не являющимися сотрудниками
                            корпоративной системы, и не имеющими доступ через СКУД. Для каждого случая временный доступ
                            приходилось согласовывать и регистрировать вручную.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui2.png" alt="" className={styles.img}/>
                    <section>
                        <h2>НАЧАЛО РАЗРАБОТКИ</h2>
                        <p><b>1 Этап</b></p>
                        <p>
                            Начинать разработку такого непростого продукта пришлось с достаточно подробной и скрупулёзной
                            аналитики, которая включала в себя проработку архитектуры будущего решения, изучение
                            возможностей сервисов заказчика, проработку структуры базы данных.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui3.png" alt="" className={styles.img}/>
                    <section>
                        <p><b>2 Этап</b></p>
                        <p>
                            Разработку самих сервисов начали по принципу "documentation first", то есть с разработки
                            спецификация API, так как API требовалось согласовывать с заказчиком. Параллельно дизайнеры
                            приступили к отрисовке макетов. Всего было несколько узлов, для которых требовалась интерфейсная
                            часть: кабинет администратора, приложение онлайн верификации и приложение стойки регистрации.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui4.png" alt="" className={styles.img}/>
                    <section>
                        <p><b>3 Этап</b></p>
                        <p>
                            После согласования всей проектной документации приступили к разработке кабинета администратора.
                            API запрограммировали параллельно интерфейсу благодаря тому, что документация уже была написана
                            и API было легко макетировать.
                        </p>
                        <p>
                            На выходе получили простое приложение, состоящее из нескольких страниц, которое предоставляет
                            возможность выполнения основных операций с сущностями системы и возможность управления доступом
                            для каждой учетной записи. Управление доступом предполагает указание возможности создания
                            заявок, конкретных этажей, на которые сотрудник имеет право приглашать гостей, а также
                            парковок, доступ к которым сотрудник сможет предоставить гостю.
                        </p>
                        <p>
                            Также была добавлена возможность принудительной синхронизации записей по кнопке, поскольку
                            система хранит копии записей из корпоративной сети заказчика с минимально-необходимой
                            информацией.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui5.png" alt="" className={styles.img}/>
                    <section>
                        <p><b>4 Этап</b></p>
                        <p>
                            Следующим этапом являлась разработка API кабинета пользователя. Поскольку на момент начала
                            разработки доступ ко многим сервисам был в процессе согласования, их приходилось макетировать
                            для сохранения темпа работы. Несмотря на это, в дальнейшем макеты сервисов все равно
                            приходилось уточнять.
                        </p>
                        <p>
                            Результатом разработки API кабинета пользователя стал сервис, с помощью которого возможно
                            создавать гостей, получать данные о своей учетной записи, создавать и редактировать заявки.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui6.png" alt="" className={styles.img}/>
                    <section>
                        <p><b>5 Этап</b></p>
                        <p>
                            В дальнейшем к возможностям сервиса кабинета пользователя была добавлена авторизация по
                            стандарту Oauth2.0, автоматическое удаление персональных данных, расширенное логирование в
                            консоль и в базу данных, интеграция с Vault Secret для хранения секретных данных, интеграция с
                            Minio для хранения картинок.
                        </p>
                        <p>
                            Важной частью системы является приложение стойки регистрации. Оно позволяет пользователю
                            произвести верификацию через паспорт в дату заявки и получить бумажный пропуск. Для этого было
                            разработано клиентское приложение, которое использует внутреннее API стойки регистрации,
                            размещается на нем и отправляет запросы в API кабинета пользователя для верификации в нашей
                            системе. Стойка регистрации имеет под капотом ПО распознавания документов, данные из которого
                            по результатам подставляются в клиентское приложение.
                        </p>
                        <p>
                            Интеграция с API стойки регистрации предполагала импорт определенных библиотек и подписку на
                            события терминала, срабатывание которых должно обрабатываться нашими функциями. Поначалу
                            интеграция представляла определенную сложность, так как API не было хорошо документировано и
                            часть решений работала не так как ожидалось или не работала совсем. Тем не менее, в процессе
                            разработки удалось преодолеть трудности и получить на выходе результат.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui7.png" alt="" className={styles.img}/>
                    <section>
                        <p><b>6 Этап</b></p>
                        <p>
                            Последней и немаловажной частью являлось приложение онлайн верификации. Оно позволяло пройти
                            верификацию онлайн через фотографию документов.
                        </p>
                        <p>
                            Приложение онлайн верификации состоит из двух частей - клиентской и серверной.
                        </p>
                        <p>
                            <b>Серверная часть</b> имеет несколько методов для отправки запроса верификации и для получения
                            изображения штрихкода. <b>Клиентская часть</b> состоит из страницы выбора способа верификации
                            (фото
                            паспорта/ЕСИА).
                        </p>
                        <p>
                            Страница выбора доступна только по правильно составленной ссылке, приложение проверяет
                            параметры в url страницы на валидность и в случае ошибки отправляет на страницу ошибки. После
                            прикрепления фото отправляется запрос в API сервиса распознавания документов, результаты
                            перенаправляются в API кабинета пользователя и обрабатываются.
                        </p>
                        <p>
                            Далее при успешной верификации система направляет ссылку на картинку штрихкода в смс на
                            указанный в заявке номер. При переходе пользователь попадает на другой маршрут приложения
                            онлайн верификации, которое согласно данным из url запрашивает картинку штрихкода и показывает
                            на странице. Таким образом с этой ссылкой можно пройти через СКУД систему в указанный день.
                        </p>
                    </section>
                    <img src="/Pictures/cases/skud/ui8.png" alt="" className={styles.img}/>
                    <section>
                        <h2>ИТОГ</h2>
                        <p>
                            Результатом работы над проектом стала система, которая полностью интегрируется с сервисами
                            заказчика и размещается в его контуре, обеспечивая безопасный доступ к ресурсам и автоматизируя
                            бизнес-процессы приглашения гостей в офисы.
                        </p>
                        <p>
                            На данный момент этот проект является одним из самых технически сложных. Работа над ним сильно
                            продвинула навыки всей команды и компетенции компании в целом.
                        </p>
                    </section>
                </article>
                <div className={styles.block}>
                    <h2 className="block-title" id="Cases">ДРУГИЕ КЕЙСЫ</h2>
                    <CaseComponent caseObj={cases[Case.Prokrasivoe]}/>
                    <CaseComponent caseObj={cases[Case.Gradient]}/>
                </div>

            </div>
            <Contacts/>
            <Footer/>
        </>
    )
}

//@ts-ignore
export default Skud;//