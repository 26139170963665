import "./when-need.css"
const WhenNeedSoftware = () => {
    return(
        <div className='when-need'>
            <h2 className='block-title'>КОГДА НУЖНО ПО</h2>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Расширение компании или значительные изменения в её работе </div>
                    <div className='need-text'>Стандартное ПО может оказаться неспособным справиться с возросшей нагрузкой или адаптироваться к новым процессам.</div>
                </div>

            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Технологические достижения</div>
                    <div className='need-text'>Стандартные цифровые решения часто не обеспечивают необходимую функциональность при внедрении технологически передовых процессов.</div>
                </div>

            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Информационная и финансовая безопасность</div>
                    <div className='need-text'>Разработка ПО на заказ позволяет реализовать надёжные меры безопасности, соответствующие требованиям бизнеса.</div>
                </div>

            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Уникальные потребности компании</div>
                    <div className='need-text'>Индивидуальное программное обеспечение разрабатывается с учётом специфических требований конкретной компании.</div>
                </div>
            </div>
        </div>
    )
}
export default WhenNeedSoftware