import React, {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import TitleMain from "../components/title/TitleMain";
import Partners from "../components/partners/Partners";
import Services from "../components/services/Services";
import Values from "../components/values/Values";
import CasesOld from "../components/cases-old/CasesOld";
import Questions from "../components/questions/Questions";
import Contacts from "../components/contacts/Contacts";
import Footer from "../components/footer/Footer";
import Vacancies from "../components/vacancies/Vacancies";
import Advantages from "../components/advantages/Advantages";
import Copyright from "../components/copyright/Copyright";
import QuestionsPopup from "../components/questions-popup/QuestionsPopup";

const MainPage = () => {
    const location = useLocation();
    useEffect(() => {
        window.scroll(0, 0);
    })
    useEffect(() => {
        if (location.state?.scrollToVacancies) {
            // Если передано состояние, скроллим к блоку с id "vacancies"
            const block = document.getElementById("vacancies");
            if (block) {
                const elementPosition = block.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - 100; // Отступ сверху
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);
    return(
        <>
            <TitleMain/>
            <Partners/>
            <Services/>
            <Values/>
            <CasesOld/>
            <Vacancies/>
            <Advantages/>
            <Questions/>
            <Contacts/>
            <Footer/>
            <Copyright/>
        </>
        )
}
export default MainPage