import styles from "./cases-page.module.css";
import ProbSol, {PSType} from "../../components/problem-solution/ProbSol";
import CaseComponent from "../../components/cases/Case";
import cases, {Case} from "../../mock/cases";
import Contacts from "../../components/contacts/Contacts";
import Footer from "../../components/footer/Footer";
import {useEffect} from "react";

const Prokrasivoe = () => {
    useEffect(() => {
        window.scroll(0, 0);
    })
    return (
        <>
            <div className={styles.container}>
                <article>
                    <div className={styles.readingTime}>Время прочтения ~ 15 минут</div>
                    <h1>Маркетплейс товаров в сфере косметологии</h1>
                    <h2>
                        Заказчик: клиника современной медицины "Медлайн"
                    </h2>
                    <ProbSol type={PSType.Problem}>
                        <p>Разработка маркетплейса для размещения косметологических товаров.</p>
                    </ProbSol>
                    <ProbSol type={PSType.Solution}>
                        <p>
                            Поставленная заказчиком задача была достаточно амбициозна. Требовалось не просто разработать
                            сайт онлайн-магазин, речь шла именно про маркетплейс, на который предполагается запускать
                            поставщиков и покупателей.
                        </p>
                        <p>
                            Следовательно, требовалось создание ролевой авторизации, инструментов размещения товаров,
                            системы каталога и покупки, модерирования и пр.
                        </p>
                    </ProbSol>
                    <img src="/Pictures/cases/prokrasivoe/ui1.png" alt="Мой кабинет" className={styles.img}/>
                    <section>
                        <h2>Описание проблемы</h2>
                        <p>
                            Основной проблемой заказчика являлась потребность в масштабировании продаж, привлечения новых
                            клиентов, а также освоении нового рынка уже в качестве агрегатора, а не коммерсанта.
                        </p>
                    </section>
                    <img src="/Pictures/cases/prokrasivoe/ui2.png" alt="Мой кабинет" className={styles.img}/>
                    <section>
                        <h2>Начало разработки</h2>
                        <h3>1 Этап</h3>
                        <p>
                            Разработку традиционно начали с дизайн-макета. В процессе работы над макетом было принято
                            разбить процесс разработки на несколько этапов:
                        </p>
                        <ul className={styles.dash}>
                            <li>
                                главная страница, фичи покупки товаров и формирования заказов, фичи кабинета косметолога
                                (покупателя)
                            </li>
                            <li>кабинет поставщика</li>
                            <li>кабинет администратора</li>
                        </ul>
                        <p>
                            Таким образом, в первую очередь отрисовали кабинет косметолога и главную страницу в мобильном
                            разрешении. Предполагалось, что у косметолога будет адаптивная мобильная версия, в то время как
                            у поставщика и администратора будет только десктопная.
                        </p>
                        <p>
                            В процессе работы столкнулись с некоторыми трудностями при разработке UI. Было достаточно много
                            непростых UI элементов, которые не имели подходящих аналогов среди доступных UI фреймворков. В
                            результате такие компоненты пришлось разрабатывать с нуля.
                        </p>
                        <p>
                            Самыми тяжелыми и наполненными с точки зрения функционала оказались страницы покупки. Они же
                            сейчас являются самыми прогрессивными, что является особой гордостью всей проектной команды.
                        </p>
                        <p>
                            В результате работы мы получили наше приложение-маркетплейс в своем первоначальном виде с
                            функционалом кабинета косметолога, была полностью реализована вся цепочка оформления заказа от
                            авторизации и поиска товара в каталоге, до его обработки в корзине, формирования заказа и
                            заполнения деталей заказа.
                        </p>
                    </section>
                    <img src="/Pictures/cases/prokrasivoe/ui3.png" alt="Категории" className={styles.img}/>
                    <section>
                        <h3>2 Этап</h3>
                        <p>
                            Следующим этапом являлась разработка кабинета поставщика. Его функционал принципиально
                            отличался, и речь здесь не только про разницу между мобильной и десктопной версией. Домашняя
                            страница поставщика включает в себя элементы, которые показывают статистику по его заказам, при
                            этом одновременно являются ссылками на соответствующие страницы.
                        </p>
                        <p>
                            Все, потому что поставщик должен иметь возможность не просто создавать и размещать товары,
                            поставщику необходимо мониторить заказы на товары, которые у него приобретает косметолог. В
                            этом ему помогает система статусов и страницы заказов с фильтрами по статусам.
                        </p>
                        <p>
                            Отдельно стоит упомянуть про процесс заведения товаров. За создание товаров на платформе
                            непосредственно отвечает администратор. Поставщик только выбирает из тех товаров, которые уже
                            существуют, и устанавливает на них свои количество и цену - так устроено создание товаров.
                            Однако поставщик может создать заявку на добавление нового товара на платформу, которая будет
                            модерироваться администратором.
                        </p>
                        <p>
                            Чтобы поставщик мог опубликовать товар на платформе, ему необходимо заплатить баллы платформы.
                            Балловая система предполагает ежедневное списание баллов за размещение. на этом построена
                            коммерциализация агрегатора платформы.
                        </p>
                        <p>
                            Большой заслугой команды также является собственный чат, разработанный на основе SignalR hub
                            специально для этого проекта. В чате решаются все вопросы касательно товаров и заказов между
                            поставщиком и косметологом. Сообщения обновляются онлайн без перезагрузки страницы, есть
                            возможность отправки ссылок, счетчики сообщений и уведомления о новых сообщениях.
                        </p>
                    </section>
                    <img src="/Pictures/cases/prokrasivoe/ui4.png" alt="Заказы" className={styles.img}/>
                    <section>
                        <h3>3 Этап</h3>
                        <p>Завершающим этапом в MVP проекта являлся кабинет администратора.</p>
                        <p>
                            <b>Администратор</b> отвечает за модерацию и добавление новых товаров, категорий и
                            подкатегорий, фильтров для каталога, базовые настройки платформы и управление учетными записями.
                        </p>
                        <p>
                            Для страниц администратора также был отрисован макет. Многие страницы были скопированы с
                            кабинета поставщика и адаптированы, так как администратору не требовался для работы уникальный
                            продвинутый UI, как, например, косметологу.
                        </p>
                    </section>
                    <img src="/Pictures/cases/prokrasivoe/ui5.png" alt="Кабинет администратора" className={styles.img}/>
                    <section>
                        <h2>Итог</h2>
                        <p>
                            Как результат всей разработки получили полноценный маркетплейс с возможностью размещения и
                            покупки товаров, прогрессивным чатом, системой покупки и многоролевой авторизацией.
                        </p>
                        <p>
                            В дальнейшем к MVP было добавлено больше количество новых фич, которые расширяют возможности
                            маркетплейса, и в дальнейшем могут приблизить его фактически к статусу социальной сети.
                        </p>
                        <p>
                            Сейчас можно с уверенностью сказать, что разработанное решение в своем роде уникально и
                            прогрессивно, работа над ним была интересна и сильно продвинула вперед команду.
                        </p>
                    </section>
                </article>
                <div className={styles.block}>
                    <h2 className="block-title" id="Cases">ДРУГИЕ КЕЙСЫ</h2>
                    <CaseComponent caseObj={cases[Case.Gradient]}/>
                    <CaseComponent caseObj={cases[Case.SKUD]}/>
                </div>
            </div>
            <Contacts/>
            <Footer/>
        </>
    )
}
export default Prokrasivoe