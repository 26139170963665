const Awards = () => {
    return(
        <div className='awards'>
            <h2 className='block-title'>НАГРЫДЫ</h2>
            <div className='award'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img src='/Pictures/services/award.svg'/>
                </div>
                <div className='award-text'>Лучшие проекты НТИ в 2022 и 2023 годах</div>
            </div>
            <div className='award'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img src='/Pictures/services/award.svg'/>
                </div>
                <div className='award-text'>StartUp Tour</div>
            </div>
            <div className='award'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img src='/Pictures/services/award.svg'/>
                </div>
                <div className='award-text'>Лучшая ИТ-компания Пермского Края</div>
            </div>
        </div>
    )
}
export default Awards