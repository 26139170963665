import "./services-page.css"
import ButtonViewCase from "../../components/services-components/ButtonViewCase";
import {useNavigate} from "react-router-dom";
import WhenNeedSoftware from "../../components/services-components/when-need/WhenNeedSoftware";
import DesiningMvp from "../../components/services-components/designing-mvp/DesiningMvp";
import ForWhatMvp from "../../components/services-components/forwhatmvp/ForWhatMvp";
import StagesDevelopment from "../../components/services-components/stagesdevelopment/StagesDevelopment";
import CasesOld from "../../components/cases-old/CasesOld";
import TechnologyStack from "../../components/services-components/technology-stack/TechnologyStack";
import {useEffect} from "react";
import Principles from "../../components/services-components/principles/Principles";
import Partners from "../../components/partners/Partners";
import Awards from "../../components/services-components/awards/Awards";
import TermsSoftware from "../../components/services-components/terms-and-cost/TermsSoftware";
import PaymentFormats from "../../components/services-components/payment-formats/PaymentFormats";
import Questions from "../../components/questions/Questions";
import Services from "../../components/services/Services";
import Contacts from "../../components/contacts/Contacts";
import Footer from "../../components/footer/Footer";
import Copyright from "../../components/copyright/Copyright";
const Software = () => {
    useEffect(() => {
        window.scroll(0, 0);
    })
    const navigate = useNavigate();
    return (
        <>
            <div>
                <div className='block-title'>РАЗРАБОТКА ОТЕЧЕСТВЕННОГО ПО</div>
                <div className='serv-title-descr'>
                    Разрабатываем сложное ПО под индивидуальные задачи бизнеса
                </div>
                <div className='picture-btn'>
                    <div className='pic-effect'>
                        <div className='effect'></div>
                        <img className='serv-picture' src='/Pictures/services/software-gradient.svg'/>
                    </div>
                    <ButtonViewCase onClick={() => {navigate('/cases/gradient')}}/>
                </div>
            </div>
            <WhenNeedSoftware/>
            <DesiningMvp/>
            <ForWhatMvp/>
            <StagesDevelopment/>
            <CasesOld/>
            <TechnologyStack/>
            <Principles/>
            <Partners/>
            <Awards/>
            <TermsSoftware/>
            <PaymentFormats/>
            <Questions/>
            <Services/>
            <Contacts/>
            <Footer/>
            <Copyright/>
        </>



    )
}
export default Software