const ForWhatMvp = () => {
    return(
        <div className='for-what-mvp'>
            <h2 className='block-title'>ДЛЯ ЧЕГО НУЖЕН MVP</h2>
            <div className='mvp-arguments'>
                <div className='mvp-argument'>
                    <img className='argument-icon' src='/Pictures/services/arg-icon1.svg'/>
                    <p className='argument-text'>Получить объективную обратную связь от пользователей и понять,
                        что нужно еще внедрить, а что убрать</p>
                </div>
                <div className='mvp-argument'>
                    <img className='argument-icon' src='/Pictures/services/arg-icon2.svg'/>
                    <p className='argument-text'>Снизить временные и финансовые затраты, а также, риски, что пользователям не понравится продукт</p>
                </div>
                <div className='mvp-argument'>
                    <img className='argument-icon' src='/Pictures/services/arg-icon3.svg'/>
                    <p className='argument-text'>Быстро выйти на рынок с самым главным функционалом. Весь последующий функционал разрабатывать в процессе</p>
                </div>
            </div>
            <div className='conclusion'>
                <h3 className='conclusion-title'>ИТОГ</h3>
                <div className='text-block'>
                    <img className='bell' src='/Pictures/services/bell.svg'/>
                    <div className='conclusion-text-and-link'>
                        <div className='conclusion-text'>Если начать разработку с МВП можно хорошо сэкономить временные и денежные затраты, за счет обратной связи от пользователей. Пользователи сами расскажут, какие именно функции им нужны, а какие нет. Поэтому, вы не будете тратить лишний бюджет и время на разработку ненужного функционала</div>
                        <div className='conclusion-link'>**Читайте статью о том, как мы работали с проектом без стадии МВП и много чего переделали</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForWhatMvp