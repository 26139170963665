import React, {useContext} from "react";
import {QuestionsContext} from "../../App";

const TitleAbout = () => {
    const setQuestionsModalVisible = useContext(QuestionsContext);
    const handleQuestionsClick = () => {
        if(!scrollTo('questions')) {
            setQuestionsModalVisible(true)
        }
    }
    const scrollTo = (elementId: string): boolean => {
        const block = document.getElementById(elementId);
        if (block) {
            const elementPosition = block.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 100;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
            return true
        }
        return false
    };
    return(
        <div className="title">
            <h2 className="company">КОМПАНИЯ</h2>
            <h1 className="corp-name">
                <b style={{ color: "#5CC3FD" }}>В</b><b>Р</b>
            </h1>
            <p className="title-second-text">Взгляни на мир с другой стороны</p>
            <div className="title-order" onClick={handleQuestionsClick}>Оставить заявку</div>

        </div>
    )
}
export default TitleAbout