const TermsMobile = () => {
    return (
        <div className='terms-and-cost'>
            <h2 className='block-title'>СРОКИ И СТОИМОСТЬ</h2>
            <div className='cost-about'>Стоимость проекта и сроки рассчитываются индивидуально под каждый проект</div>
            <div className='table-terms-cost'>
                <div className='table-row'>
                    <div className='row-left'>Тип проекта</div>
                    <div className='row-right'>Мобильное приложение</div>
                </div>
                <div className='table-row'>
                    <div className='row-left'>Срок работы</div>
                    <div className='row-right'>От 2-ух месяцев</div>
                </div>
                <div className='table-row'>
                    <div className='row-left'>Стоимость</div>
                    <div className='row-right'>От 1,5 млн. рублей</div>
                </div>
            </div>
        </div>
    )
}
export default TermsMobile