import styles from "./cases-page.module.css";
import ProbSol, {PSType} from "../../components/problem-solution/ProbSol";
import CaseComponent from "../../components/cases/Case"
import cases, {Case} from "../../mock/cases";
import Footer from "../../components/footer/Footer";
import Contacts from "../../components/contacts/Contacts";
import {useEffect} from "react";

const Gradient = () => {
    useEffect(() => {
        window.scroll(0, 0);
    })
    return (
        <>
            <div className={styles.container}>
                <article>
                    <div className={styles.readingTime}>Время прочтения ~ 15 минут</div>
                    <h1>ПО ДЛЯ ПРОГРАММНО-АППАРАТНОГО КОМПЛЕКСА «АЛКО-ИНСПЕКТОР СОФТ»</h1>
                    <div className={styles.client}>
                        <h2>ЗАКАЗЧИК: ООО "ГРАДИЕНТ"</h2>
                        <img
                            src="/Pictures/partners-logo/gradient.png"
                            alt="Градиент: металл под контролем"
                            className={styles.logo}
                        />
                    </div>
                    <ProbSol type={PSType.Problem}>
                        <p className={styles.regular}>
                            Разработать собственное ПО для программно-аппаратного комплекса «Алко-Инспектор».
                        </p>
                    </ProbSol>
                    <ProbSol type={PSType.Solution}>
                        <p className={styles.regular}>
                            Разработка программного обеспечения для синхронизации алкотестера, сканера лиц и персонального
                            компьютера для совместной работы в проведении проверки сотрудников на алкоголь
                        </p>
                    </ProbSol>
                    <img src="/Pictures/cases/gradient/ui1.png" alt="" className={styles.img}/>
                    <section>
                        <h2>ОПИСАНИЕ ЗАКАЗЧИКА:</h2>
                        <p>
                            Программно-аппаратный комплекс «Алко Инспектор Софт» предназначен для отображения информации в
                            реальном времени через веб-интерфейс и регистрации событий о проверках «посетителей или
                            сотрудников»
                            (далее люди). Выводимая в реальном времени информация, включает данные о проходах людей, которые
                            есть в базе данных, температуры тела людей, а также отметках о наличии признаков алкогольного
                            опьянения на основе анализа выдыхаемого воздуха в алкотестер. Зафиксированные данные сохраняются
                            в локальной базе данных, а оператор устройства уведомляется о факте прохождения лица,
                            находящегося в состоянии алкогольного опьянения.
                        </p>
                    </section>
                    <section>
                        <h2>ОПИСАНИЕ ПРОБЛЕМЫ:</h2>
                        <p>
                            Все чаще предприятия сталкиваются с ситуацией, когда сотрудник может прийти на работу в
                            состоянии
                            алкогольного опьянения. Отсутствие средств контроля состояния сотрудников при приходе на работу
                            и
                            заступления на смену приводит к самым различным неприятным последствиям. Компания ООО «Градиент»
                            совместно с компанией ООО «ВР» заинтересовались в представлении решения данной проблемы путем
                            создания отечественного комплекса «Алко-Инспектор», состоящего из сканера распознавания лиц,
                            устройства для проверки вдыхаемого в него воздуха на наличие паров алкоголя и устройства вывода
                            информации о прохождении проверки на алкоголь.
                        </p>
                    </section>
                    <img src="/Pictures/cases/gradient/ui2.png" alt="" className={styles.img}/>
                    <section>
                        <h2>ЭТАПЫ РАЗРАБОТКИ</h2>
                        <ol>
                            <li>Выбор архитектуры для будущей системы</li>
                            <li>Проектирование структуры базы данных</li>
                            <li>Разработка дизайн-макета будущей системы</li>
                            <li>
                                Разработка клиентской части
                                <ul className={styles.disc}>
                                    <li>Настройка связи с устройством распознавания лиц</li>
                                    <li>Настройка связи с анализатором алкоголя</li>
                                    <li>Разработка цикла продувки пользователей вместе с этими двумя устройствами</li>
                                    <li>Разработка компонентов для настройки системы</li>
                                    <li>
                                        Разработка компонентов для обеспечения возможности добавления, редактирования и
                                        удаления
                                        учетных записей пользователей.
                                    </li>
                                    <li>Регистрация анонимных пользователей</li>
                                </ul>
                            </li>
                            <li>
                                Разработка серверной части
                                <ul className={styles.disc}>
                                    <li>Разработка сервиса синхронизации между сервером и клиентом</li>
                                    <li>Внедрение логирования ошибок системы</li>
                                    <li>Разработка компонентов для записи истории продувок пользователей</li>
                                    <li>Разработка функционала по созданию и генерации отчетов</li>
                                </ul>
                            </li>
                            <li>
                                Тестирование и развертывание
                            </li>
                        </ol>
                    </section>
                    <img src="/Pictures/cases/gradient/ui3.png" alt="" className={styles.img}/>
                    <section>
                        <h2>ПОДРОБНОЕ ОПИСАНИЕ РАЗРАБОТКИ</h2>
                        <ol className={styles.bold}>
                            <li>
                                Выбор архитектуры для будущей системы
                                <p>
                                    Разработка началась с выбора архитектуры для будущей системы. Была выбрана
                                    клиент-серверная
                                    модель. Выбор клиент-серверной архитектуры для будущей системы является распространенным
                                    и
                                    эффективным подходом. Эта модель предполагает, что система состоит из двух основных
                                    компонентов:
                                </p>
                                <p>
                                    <b>Клиент</b> — программное обеспечение, установленное на локальном устройстве
                                    (например,
                                    компьютер, мобильное устройство), которое взаимодействует с пользователем и отправляет
                                    запросы на сервер.
                                </p>
                                <p>
                                    <b>Сервер</b> — централизованный компонент, который обрабатывает запросы от клиентов,
                                    выполняет необходимую логику и отправляет ответы обратно клиентам.
                                </p>
                                <h3>Основные преимущества клиент-серверной архитектуры:</h3>
                                <ul className={styles.disc}>
                                    <li>Централизованное управление и обработка данных на сервере</li>
                                    <li>
                                        Возможность масштабирования системы путем добавления дополнительных серверных
                                        мощностей
                                    </li>
                                    <li>Безопасность за счет контролируемого доступа к серверу</li>
                                    <li>
                                        Разделение обязанностей между клиентом и сервером, что упрощает разработку и
                                        поддержку
                                    </li>
                                    <li>Возможность доступа к системе с различных устройств через клиентские приложения</li>
                                </ul>
                                <p>
                                    Выбор этой архитектуры позволит создать надежную, масштабируемую и безопасную систему,
                                    которая будет эффективно справляться с поставленными задачами.
                                </p>
                            </li>
                            <li>
                                Разработка структуры БД
                                <p>
                                    Разработка структуры базы данных (БД) является ключевым этапом при проектировании
                                    клиент-серверной системы. БД будет служить хранилищем всех необходимых данных,
                                    таких как учетные записи пользователей, история продувок, настройки системы и т.д.
                                </p>
                                <p>При проектировании структуры БД необходимо учитывать следующие
                                    аспекты:</p>
                                <ul className={styles.disc}>
                                    <li>Определение сущностей (таблиц) и их взаимосвязей</li>
                                    <li>Проектирование схемы данных с учетом нормализации</li>
                                    <li>Выбор подходящих типов данных для каждого поля</li>
                                    <li>Определение ключевых полей и индексов для ускорения выборки данных</li>
                                    <li>
                                        Продумывание механизмов обеспечения целостности данных (например, использование
                                        внешних
                                        ключей, триггеров)
                                    </li>
                                </ul>
                                <p>
                                    Тщательное проектирование структуры БД позволит создать надежное хранилище данных,
                                    которое
                                    будет эффективно поддерживать функционирование всей системы.
                                </p>
                            </li>
                            <img src="/Pictures/cases/gradient/ui4.png" alt="" className={styles.img}/>
                            <li>
                                Разработка дизайн-макета будущей системы
                                <p className={styles.regular}>
                                    Проектирование дизайн-макета будущей системы является важным шагом, который должен
                                    учитывать
                                    следующие аспекты:
                                </p>
                                <ul className={styles.disc}>
                                    <li>
                                        Определение общей концепции и стилистики интерфейса (цветовая палитра, типографика,
                                        иконография)
                                    </li>
                                    <li>
                                        Разработка макетов основных экранов и компонентов (страница авторизации, главное
                                        меню,
                                        страница управления пользователями, страница отчетов и т.д.)
                                    </li>
                                    <li>Продумывание удобной и интуитивно понятной навигации</li>
                                    <li>Обеспечение соответствия дизайна фирменному стилю или брендовым требованиям</li>
                                    <li>Учет принципов юзабилити и доступности для различных категорий пользователей</li>
                                    <li>Создание адаптивного дизайна, позволяющего корректно отображаться на разных
                                        устройствах
                                    </li>
                                    <li>Разработка прототипов для оценки и тестирования интерфейса</li>
                                </ul>
                                <p className={styles.regular}>
                                    Качественно разработанный дизайн-макет обеспечит привлекательный и эргономичный
                                    пользовательский интерфейс, что повысит удобство использования системы.
                                </p>
                            </li>
                            <li>
                                Разработка клиентской части
                                <p>
                                    Разработка клиентской части является важным этапом в создании клиент-серверной системы.
                                    Клиентская часть отвечает за пользовательский интерфейс и взаимодействие с
                                    пользователем.
                                    Она включает в себя разработку интуитивно понятного и привлекательного дизайна,
                                    реализацию
                                    функциональности, такой как отображение данных, обработка запросов пользователя и
                                    обеспечение безопасности.
                                </p>
                                <p>Для реализации функциональности клиентской части системы были
                                    выполнены следующие шаги:</p>
                                <ul className={`${styles.bold} ${styles.disc}`}>
                                    <li>
                                        Настройка связи с устройством распознавания лиц
                                        <p>
                                            Настройка связи с устройством распознавания лиц является важным компонентом
                                            клиентской
                                            части системы.
                                        </p>
                                        <h3>Это подразумевает следующие шаги:</h3>
                                        <ul className={styles.disc}>
                                            <li>
                                                Интеграция программного интерфейса (API) устройства распознавания лиц в
                                                клиентское приложение
                                            </li>
                                            <li>
                                                Настройка параметров взаимодействия, таких как адрес устройства, протокол
                                                связи,
                                                формат передаваемых данных
                                            </li>
                                            <li>
                                                Разработка логики обработки ответов от устройства распознавания (например,
                                                получение идентификатора пользователя, оценка степени достоверности)
                                            </li>
                                        </ul>
                                        <p>
                                            Успешная интеграция клиента с устройством распознавания лиц позволит обеспечить
                                            эффективную идентификацию пользователей, что является ключевым требованием для
                                            всей
                                            системы.
                                        </p>
                                    </li>
                                    <img src="/Pictures/cases/gradient/ui5.png" alt="" className={styles.img}/>
                                    <li>
                                        Настройка связи с анализатором алкоголя
                                        <p>
                                            Также необходимо обеспечить интеграцию клиентского приложения с анализатором
                                            алкоголя. Это включает в себя следующие шаги:
                                        </p>
                                        <ul className={styles.disc}>
                                            <li>
                                                Интеграция программного интерфейса (API) анализатора алкоголя в клиентское
                                                приложение
                                            </li>
                                            <li>
                                                Настройка параметров взаимодействия, таких как адрес устройства, протокол
                                                связи,
                                                формат передаваемых данных
                                            </li>
                                            <li>
                                                Разработка логики обработки ответов от анализатора алкоголя (например,
                                                получение
                                                результата измерения концентрации алкоголя)
                                            </li>
                                        </ul>
                                        <p className={styles.regular}>
                                            Успешная интеграция клиента с анализатором алкоголя позволит получать
                                            достоверные
                                            данные о состоянии пользователей, что необходимо для эффективного
                                            функционирования
                                            всей системы.
                                        </p>
                                    </li>
                                    <li>
                                        Разработка цикла продувки пользователей вместе с этими двумя устройствами
                                        <p>
                                            Разработка цикла продувки пользователей является ключевым компонентом клиентской
                                            части системы. Этот цикл должен объединять взаимодействие с устройством
                                            распознавания лиц и анализатором алкоголя для комплексной проверки
                                            пользователей.
                                        </p>
                                        <h3>Основные шаги цикла:</h3>
                                        <ol>
                                            <li>Инициирование цикла проверки пользователя (когда он подошел к устройству
                                                распознавания лиц)
                                            </li>
                                            <li>Получение результата идентификации пользователя</li>
                                            <li>Запрос измерения уровня алкоголя от анализатора</li>
                                            <li>Получение результата измерения уровня алкоголя</li>
                                            <li>Оценка результатов идентификации и измерения алкоголя</li>
                                            <li>Предоставление результата проверки пользователю (допуск/отказ)</li>
                                            <li>Логирование результатов проверки</li>
                                        </ol>
                                        <p>
                                            Реализация данного цикла должна обеспечивать надежную и эффективную проверку
                                            состояния пользователей, с использованием данных от обоих подключенных
                                            устройств. Необходимо также предусмотреть обработку ошибок, повторные попытки и
                                            другие сценарии для обеспечения бесперебойной работы.
                                        </p>
                                    </li>
                                    <img src="/Pictures/cases/gradient/ui6.png" alt="" className={styles.img}/>
                                    <li>
                                        Разработка компонентов для обеспечения возможности настройки системы
                                        <p>
                                            Для обеспечения гибкости и адаптируемости системы к различным требованиям,
                                            необходимо разработать компоненты, позволяющие настраивать ее параметры.
                                        </p>
                                        <p>Это может включать в себя:</p>
                                        <ul className={styles.disc}>
                                            <li>Страницу администрирования системных настроек (например, параметры
                                                подключения
                                                устройств, частота проверок, пороговые значения)
                                            </li>
                                            <li>Возможность изменения логики работы системы (например, настройка алгоритмов
                                                идентификации, обработки результатов проверки)
                                            </li>
                                            <li>Инструменты для управления учетными записями пользователей (добавление,
                                                редактирование, удаление)
                                            </li>
                                        </ul>
                                        <p>
                                            Реализация данных компонентов позволит администраторам гибко управлять
                                            конфигурацией
                                            системы, адаптируя ее под конкретные потребности.
                                        </p>
                                    </li>
                                    <li>
                                        Разработка компонентов для обеспечения возможности добавления, редактирования и
                                        удаления
                                        учетных записей пользователей.
                                        <p>
                                            Для управления пользователями системы необходимо разработать
                                            следующие компоненты:
                                        </p>
                                        <ul className={styles.disc}>
                                            <li>Страница для просмотра списка зарегистрированных пользователей</li>
                                            <li>
                                                Форма для добавления новых пользователей с возможностью указания необходимых
                                                данных (имя, фамилия, идентификатор, роль и т.д.)
                                            </li>
                                            <li>Функционал для редактирования информации об существующих пользователях</li>
                                            <li>Возможность удаления учетных записей пользователей</li>
                                            <li>Механизмы обработки ошибок при выполнении операций с пользователями</li>
                                            <li>Настройка прав доступа и ролей для различных категорий пользователей</li>
                                        </ul>
                                        <p>
                                            Реализация данных компонентов позволит администраторам эффективно управлять
                                            пользовательской базой системы, добавляя, изменяя и удаляя учетные записи по
                                            мере
                                            необходимости.
                                        </p>
                                    </li>
                                    <img src="/Pictures/cases/gradient/ui7.png" alt="" className={styles.img}/>
                                    <li>
                                        Регистрация анонимных пользователей
                                        <ol>
                                            <li>Добавлена возможность создания учетных записей для анонимных
                                                пользователей.
                                            </li>
                                            <li>При попытке прохождения цикла продувки, если пользователь не найден в базе,
                                                создается новая учетная запись с минимальными данными (ID, дата и
                                                фотография),
                                                которая хранится в системе в течение определенного периода времени.
                                            </li>
                                            <li>Такие анонимные пользователи не могут совершить продувку, однако можно
                                                преобразовать анонимную учетную запись в полноценную, запросив
                                                дополнительную
                                                информацию о пользователе, после чего он сможет проходить цикл продувки.
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Разработка серверной части
                                <p>
                                    Разработка серверной части является важным этапом при проектировании клиент-серверной
                                    системы. Серверная часть отвечает за обработку запросов от клиентской части,
                                    взаимодействие
                                    с базой данных, аутентификацию и авторизацию пользователей, а также обеспечение
                                    безопасности
                                    данных.
                                </p>
                                <p>Разработка серверной части включает в себя следующие шаги:</p>
                                <ul className={`${styles.bold} ${styles.disc}`}>
                                    <li>
                                        Разработка сервиса синхронизации между сервером и клиентом
                                        <p>
                                            На серверной стороне реализован модуль синхронизации данных, взаимодействующий с
                                            клиентским приложением.
                                        </p>
                                        <p>
                                            Сервер обеспечивает следующие функции синхронизации:
                                        </p>
                                        <ul className={styles.disc}>
                                            <li>Централизованное хранение всех данных системы (настройки, пользователи,
                                                результаты продувок, отчеты).
                                            </li>
                                            <li>Получение запросов на синхронизацию от клиентского приложения</li>
                                            <li>Обработка запросов, анализ изменений в данных</li>
                                            <li>Формирование ответов с актуальными данными для клиента</li>
                                            <li>Сохранение изменений, поступающих от клиентского приложения</li>
                                        </ul>
                                    </li>
                                    <img src="/Pictures/cases/gradient/ui8.png" alt="" className={styles.img}/>
                                    <li>
                                        Внедрение логирования ошибок системы
                                        <p>
                                            Для обеспечения качества и эффективной поддержки системы, необходимо реализовать
                                            механизмы логгирования ошибок. Это подразумевает следующие шаги:
                                        </p>
                                        <ul className={styles.disc}>
                                            <li>Определение ключевых событий и ошибок, подлежащих регистраци</li>
                                            <li>Выбор подходящего инструмента или библиотеки для логгирования (Serilog)</li>
                                            <li>
                                                Интеграция механизмов логгирования в различные компоненты системы (клиент,
                                                сервер, модули)
                                            </li>
                                            <li>
                                                Настройка параметров логгирования, таких как уровни детализации, формат
                                                записей,
                                                пути сохранения логов
                                            </li>
                                            <li>
                                                Внедрение эффективного логгирования ошибок позволит легко диагностировать и
                                                устранять возникающие проблемы, а также обеспечит прозрачность работы
                                                системы.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Разработка компонентов для записи истории продувок пользователей
                                        <p>
                                            Для ведения истории продувок пользователей необходимо реализовать следующие
                                            компоненты:
                                        </p>
                                        <ul className={styles.disc}>
                                            <li>База данных для хранения записей о проведенных проверках пользователей</li>
                                            <li>
                                                Модели данных, описывающие структуру записей (идентификатор пользователя,
                                                время,
                                                результат проверки и т.д.)
                                            </li>
                                            <li>
                                                Механизмы для сохранения результатов проверок в базу данных после их
                                                выполнения
                                            </li>
                                            <li>
                                                Страница для просмотра истории продувок, с возможностью фильтрации и
                                                сортировки
                                                записей
                                            </li>
                                        </ul>
                                        <p>
                                            Реализация данных компонентов позволит вести полный учет
                                            проведенных проверок пользователей, что необходимо для анализа и отчетности.
                                        </p>
                                    </li>
                                    <img src="/Pictures/cases/gradient/ui9.png" alt="" className={styles.img}/>
                                    <li>
                                        Разработка функционала по созданию и генерации отчетов
                                        <p>
                                            Для предоставления отчетности о работе системы необходимо реализовать следующий
                                            функционал:
                                        </p>
                                        <ul className={styles.disc}>
                                            <li>Страница для генерации отчетов (по продувкам пользователей)</li>
                                            <li>
                                                Возможность гибкой настройки параметров отчетов (период, фильтры,
                                                группировки)
                                            </li>
                                            <li>Генерация отчетов в различных форматах (страница в браузере или файл CSV)
                                            </li>
                                            <li>Механизмы для сохранения и загрузки пользовательских настроек отчетов</li>
                                            <li>Визуализация данных в отчетах с помощью диаграмм, графиков, таблиц</li>
                                        </ul>
                                        <p>
                                            Реализация данного функционала позволит администраторам и руководителям получать
                                            необходимую аналитическую информацию о работе системы, что поможет в принятии
                                            эффективных управленческих решений.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Тестирование и отладка
                                <ul className={styles.disc}>
                                    <li>
                                        Проведено всестороннее тестирование системы, включая:
                                        <ul className={styles.circle}>
                                            <li>Модульное тестирование отдельных компонентов</li>
                                            <li>Интеграционное тестирование взаимодействия между модулями</li>
                                            <li>Функциональное тестирование на соответствие требованиям</li>
                                            <li>Нагрузочное тестирование для проверки производительности</li>
                                            <li>Тестирование безопасности и защиты от атак</li>
                                        </ul>
                                    </li>
                                    <li>Выявленные ошибки и недочеты были оперативно исправлены</li>
                                    <li>
                                        Была проведена отладка системы, в том числе:
                                        <ul className={styles.circle}>
                                            <li>Оптимизация SQL-запросов и индексов в базе данных</li>
                                            <li>Устранение утечек памяти и других ресурсов</li>
                                            <li>Профилирование и точечная оптимизация производительности</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Протестировано взаимодействие с устройствами распознавания лиц и измерения алкоголя
                                    </li>
                                    <li>Выполнено развертывание системы в тестовой и продуктивной среде.</li>
                                </ul>
                            </li>
                        </ol>
                    </section>
                </article>
                <div className={styles.block}>
                    <h2 className="block-title" id="Cases">ДРУГИЕ КЕЙСЫ</h2>
                    <CaseComponent caseObj={cases[Case.Prokrasivoe]}/>
                    <CaseComponent caseObj={cases[Case.SKUD]}/>
                </div>
            </div>
            <Contacts/>
            <Footer/>
        </>
    )
}

export default Gradient;