import React, {useState} from "react";
import services from "../../mock/services";
import "./services.css"
import QuestionsPopup from "../questions-popup/QuestionsPopup";
const Services = () => {
    const [isQuestionsModalVisible, setQuestionsModalVisible] = useState(false);
    const scrollTo = (elementId: string): boolean => {
        const block = document.getElementById(elementId);
        if (block) {
            const elementPosition = block.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 100;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
            return true
        }
        return false
    };
    const handleQuestionsClick = () => {
        if(!scrollTo('questions')) {
            setQuestionsModalVisible(true)
        }
    }
    return(
        <>
            <div className="service-block">
                <h2 className="block-title">УСЛУГИ</h2>
                <div className="services">
                    {services.map(service => (
                        <div className="service">
                            <p className="card-logo-corp"><b style={{ color: "#5CC3FD" }}>В</b><b>Р</b></p>
                            <p className="service-name">{service?.name}</p>
                            <div className="card-order" onClick={handleQuestionsClick}>Оставить заявку</div>
                            <p className="hashtag">#ВЕБРАЗРАБОТКА</p>
                        </div>
                    ))}
                </div>
            </div>
            <QuestionsPopup open={isQuestionsModalVisible} setOpen={setQuestionsModalVisible} />
        </>
    )
}
export default Services;