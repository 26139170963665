const PaymentFormats = () => {

    const prosCons = [
        {
            id: 1, 
            name: 'Стоимость работ', 
            prosIcon: '/Pictures/services/fix-price.svg ',
            conIcon: '/Pictures/services/time-mat.svg',
            prosText: 'Управляемый бюджет. Оплата фактического времени работ',
            conText: 'Фиксированный бюджет, но есть вероятность, что объем работ может стать меньше',
        },
        {
            id: 2, 
            name: 'Прогноз стоимости', 
            prosIcon: '/Pictures/services/+.svg',
            conIcon: '/Pictures/services/-.svg',
            prosText: 'Приблизительный прогноз бюджета',
            conText: 'Фиксированный бюджет конкретного объема работ',
        },
        {
            id: 3, 
            name: 'Правки и корректировки требований', 
            prosIcon: '/Pictures/services/-.svg ',
            conIcon: '/Pictures/services/+.svg',
            prosText: 'Возможность уточнять и изменять требования и объем работ',
            conText: 'Требования зафиксированы для этапа работ. Для новых требований понадобится дополнительное соглашение',
        },
        {
            id: 4, 
            name: 'Транссакционные издержки (расходы по сделкам)', 
            prosIcon: '/Pictures/services/+.svg',
            conIcon: '/Pictures/services/-.svg',
            prosText: 'Постоянная синхронизация приоритетных задач',
            conText: 'Для любых новых требований необходимо дополнительное соглашение',
        },
        {
            id: 5, 
            name: 'Скорость получения результата', 
            prosIcon: '/Pictures/services/rocket.svg',
            conIcon: '/Pictures/services/time-mat.svg',
            prosText: 'МВП можно запустить в ближайший срок',
            conText: 'Проект будет выведен на рынок только после завершения финального этапа',
        },
        {
            id: 6, 
            name: 'Возможность остановить проект', 
            prosIcon: '/Pictures/services/+.svg',
            conIcon: '/Pictures/services/-.svg',
            prosText: 'В любой момент можете забрать задокументированный проект с разработанным функционалом',
            conText: 'Продукт передается только после завершения финального этапа',
        },
        {
            id: 7, 
            name: 'Время на внесение правок', 
            prosIcon: '/Pictures/services/+.svg',
            conIcon: '/Pictures/services/-.svg',
            prosText: 'В каждом спринте* выделяем время на внесение правок',
            conText: 'Для внесения правок нужно дополнительное соглашение',
        },
    ]

    return(
        <div className='payment-formats'>
            <h3 className='payments-title'>ФОРМАТЫ ОПЛАТЫ</h3>
            <p className='cost'>Стоимость работ</p>
            <div className='bell-and-time-mat'>
                <img className='bell' src='/Pictures/services/bell.svg'/>
                <div className='time-mat-text'>Time & Material (время и материалы) и Fix Price — это две разные модели оплаты в разработке веб-проектов</div>
            </div>
            <div className="time-mat-about">
                <img src="/Pictures/services/time-mat.svg" alt=""/>
                <div className='payment-text'>Time & Material — это модель, при которой заказчик оплачивает агентству время работы специалистов, а не фиксированную сумму за весь проект. Оценка объёма работ и сроков производится приблизительно, а главным является результат и качество</div>
            </div>
            <div className='fix-price-about'>
                <img src="/Pictures/services/fix-price.svg" alt="" />
                <div className='payment-text'>Fix Price — это модель, при которой заказчик оплачивает агентству определённую сумму за весь проект по заранее оговорённой стоимости и сроках выполнения работы. Оценка объёма работ и сроков производится точно, и главным приоритетом является соблюдение сроков и выделенного бюджета</div>
            </div>
            <div className='payment-link'>**Более подробно читайте в нашей статье на vc.ru</div>
            <div className='pros-and-cons'>
                <div className='pros-and-cons-title'>ПЛЮСЫ И МИНУСЫ ФОРМАТОВ ОПЛАТЫ</div>
                <div className='pros-and-cons-table'>
                    <div className='head-table'>
                        <div className='pros-and-cons-name'></div>
                        <div className='head-text'>Time & Material</div>
                        <div className='head-text'>Fix Price</div>
                    </div>

                </div>
            </div>
            {prosCons.map((item, index) => (
                <div key={index} className='pros-and-cons-row'>
                    <div className='pros-and-cons-name'>{item.name}</div>
                    <div className='column-time'>
                        <img className='row-icon' alt="" src={item.conIcon}/>
                        <div className='column-text'>{item.conText}</div>
                    </div>
                    <div className='column-fix'>
                        <img className='row-icon' alt="" src={item.prosIcon}/>
                        <div className='column-text'>{item.prosText}</div>
                    </div>
                {/*    проверка синхры*/}
                
                </div>
            ))}
        </div>
    )
}
export default PaymentFormats