import "./../../../pages/services/services-page.css"
const DesiningMvp = () =>{
    return(
        <div className='designing-the-mvp'>
            <h3 className='mvp-title'>ПРОЕКТИРУЕМ MVP</h3>
            <div className='slogan'>MVP - это минимальный жизнеспособный продукт</div>
            <div className='bell-and-text'>
                <img className='bell' src='/Pictures/services/bell.svg'/>
                <div className='text-and-link'>
                    <div className='mvp-text'>Это нужно для того, чтобы быстрее и дешевле вывести продукт на рынок, получив максимально объективную обратную связь от пользователей</div>
                    <div className='mpv-link'>**Читать подробнее о статье</div>
                </div>
            </div>
        </div>
    )
}
export default DesiningMvp