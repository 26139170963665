import {useNavigate} from "react-router-dom";
import React, {FC} from "react";
import {ICase} from "../../types";
interface ICasesProps {
    caseObj: ICase
}

const Case: FC<ICasesProps> = ({caseObj}) => {
    const navigate = useNavigate()
    return(
        <div className="case-link">
            <div className="case">
                <p className='vr-in-case'><b style={{color: '#3FB1F2'}}>В</b>Р</p>
                <p className='case-title'>{caseObj.title}</p>
                <p className='hashtag-case'>#ВЕБРАЗРАБОТКА</p>
            </div>
            <div className='link-and-description'>
                <div className='link-nda'>
                    <div className='link'>
                        <div className='link-and-button' onClick={() => {
                            navigate(`${caseObj.link}`)
                        }}>
                            <p className='link-name'>{caseObj.shortName}</p>
                            <div className='link-btn'>→</div>
                        </div>
                    </div>
                    {caseObj.ps && (
                        <p className="case-ps">{caseObj.ps}</p>
                    )}
                </div>
                <p className='description'>{caseObj.description}</p>
            </div>
        </div>
    )
}

export default Case;