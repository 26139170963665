const TechnologyStack = () => {
    const frontendRows = [
        {id: 1, name: 'React', logo: '/Pictures/services/stack-logos/react.svg'},
        {id: 2, name: 'TypeScript', logo: '/Pictures/services/stack-logos/ts.svg'},
        {id: 3, name: 'Wordpress', logo: '/Pictures/services/stack-logos/wp.svg'},
        {id: 4, name: 'Vue', logo: '/Pictures/services/stack-logos/vue.svg'},
    ]

    const backendRows = [
        {id: 1, name: 'Python', logo: '/Pictures/services/stack-logos/python.svg'},
        {id: 2, name: 'PHP', logo: '/Pictures/services/stack-logos/php.svg'},
        {id: 3, name: 'С# ASP.NET', logo: '/Pictures/services/stack-logos/aspnet.svg'},
        {id: 4, name: 'PostgreSQL', logo: '/Pictures/services/stack-logos/pg.svg'},
        {id: 5, name: 'SQL', logo: '/Pictures/services/stack-logos/sql.svg'},
    ]

    const devopsRows = [
        {id: 1, name: 'CI/CD', logo: '/Pictures/services/stack-logos/ci.svg'},
        {id: 2, name: 'Docker', logo: '/Pictures/services/stack-logos/docker.svg'},

    ]

    const mlRows = [
        {id: 1, name: 'Yolo Ultralytics', logo: '/Pictures/services/stack-logos/yolo.svg'},
        {id: 2, name: 'TensorFlow', logo: '/Pictures/services/stack-logos/tensorflow.svg'},
    ]
    return(
        <div className='stack-block'>
            <h2 className='block-title'>СТЕК ТЕХНОЛОГИЙ</h2>
            <div className='stack-chapter'>
                <p className='chapter-title'>FRONTEND</p>
                {frontendRows.map((row, ) => (
                    <div className='stack-row'>
                        <img src={row.logo}/>
                        <div className='row-name'>{row.name}</div>
                    </div>
                ))}
            </div>
            <div className='stack-chapter'>
                <p className='chapter-title'>BACKEND</p>
                {backendRows.map((row, ) => (
                    <div className='stack-row'>
                        <img src={row.logo}/>
                        <div className='row-name'>{row.name}</div>
                    </div>
                ))}
            </div>
            <div className='stack-chapter'>
                <p className='chapter-title'>DEVOPS</p>
                {devopsRows.map((row, ) => (
                    <div className='stack-row'>
                        <img src={row.logo}/>
                        <div className='row-name'>{row.name}</div>
                    </div>
                ))}
            </div>
            <div className='stack-chapter'>
                <p className='chapter-title'>ML</p>
                {mlRows.map((row, ) => (
                    <div className='stack-row'>
                        <img src={row.logo}/>
                        <div className='row-name'>{row.name}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default TechnologyStack