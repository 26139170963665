import {ICase} from "../types";
export enum Case {
    Prokrasivoe,
    Gradient,
    SKUD
}
const cases: ICase[] = [
    {
        id: Case.Prokrasivoe,
        title: 'КЛИНИКА СОВРЕМЕННОЙ МЕДИЦИНЫ "МЕДЛАЙН"',
        shortName: 'ПРОКРАСИВОЕ',
        link: '/cases/prokrasivoe',
        description: 'Маркетплейс товаров в сфере косметологии'
    },
    {
        id: Case.Gradient,
        title: 'ПО ДЛЯ ПРОГРАММНО-АППАРАТНОГО КОМПЛЕКСА  "АЛКО ИНСПЕКТОР СОФТ"',
        shortName: 'ГРАДИЕНТ',
        link: '/cases/gradient',
        description: 'Собственное ПО для программно-аппаратного комплекса «Алко-Инспектор»'
    },
    {
        id: Case.SKUD,
        title: 'ПО ИНТЕГРАЦИИ СО СКУД',
        shortName: 'БАНКОВСКАЯ СФЕРА',
        link: '/cases/skud',
        ps: '*Подписан NDA',
        description: 'Корпоративная система для приглашения гостей в офис'
    },
];

export default cases