import React, { useState } from "react";
import "./advantages.css";
import advantages from "../../mock/advantages";

const Advantages = () => {
    const [visibleAdvantage, setVisibleAdvantage] = useState<number | null>(null);

    const toggleAdvantage = (id: number) => {
        setVisibleAdvantage(visibleAdvantage === id ? null : id);
    };

    return (
        <div className='advantages-block'>
            <h2 className='block-title'>ПРЕИМУЩЕСТВА</h2>
            <div className='advantages'>
                {advantages.map((advantage, index) => (
                    <div className='advantage' key={advantage.id}>
                        <div className='adv-head' onClick={() => toggleAdvantage(index)}>
                            <div className='adv-name'>{advantage.name}</div>
                            <div className='adv-btn'>
                                {visibleAdvantage === index ? '-' : '+'}
                            </div>
                        </div>
                        <div
                            className={`adv-text ${visibleAdvantage === index ? 'open' : ''}`}
                        >
                            {advantage.text}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Advantages;
