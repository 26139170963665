import "./when-need.css"
const WhenNeedWeb = () => {
    return(
        <div className='when-need'>
            <h2 className='block-title'>КОГДА НУЖЕН ВЕБ-СЕРВИС</h2>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Необходимость автоматизации бизнес-процессов</div>
                    <div className='need-text'>Веб-сервисы помогают автоматизировать различные аспекты деятельности компании, такие как обработка заказов, учёт финансов, управление персоналом и другие.</div>
                </div>

            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Ускорение взаимодействия между сотрудниками и партнёрами</div>
                    <div className='need-text'>Веб-сервисы предоставляют инструменты для обмена информацией, совместной работы над проектами и оперативного решения возникающих вопросов.</div>
                </div>

            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Повышение эффективности работы</div>
                    <div className='need-text'>Веб-сервисы позволяют оптимизировать рабочие процессы, сокращать время на выполнение задач и снижать издержки.</div>
                </div>

            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Удовлетворение потребностей клиентов</div>
                    <div className='need-text'>Веб-сервисы могут использоваться для создания удобных интерфейсов для заказа товаров и услуг, обратной связи с клиентами и обработки их запросов.</div>
                </div>
            </div>
            <div className='need-row'>
                <div className='need-pic-effect'>
                    <div className='need-effect'></div>
                    <img className='checkmark' src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className='need'>
                    <div className='need-name'>Развитие бизнеса</div>
                    <div className='need-text'>Веб-сервисы помогают расширять географию присутствия компании, увеличивать количество клиентов и партнёров, а также повышать конкурентоспособность на рынке.</div>
                </div>
            </div>
        </div>
    )
}
export default WhenNeedWeb